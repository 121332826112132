import React, { useState } from 'react';
import { parseCookies, setCookie } from 'nookies';
import styles from './cookieNotify.module.sass';
import { Button } from '../Button';

export const CookieNotify = ({ isMobile }) => {
  const notifyState = parseCookies()?.closeNotifyCookie;
  const [onOpen, setOpen] = useState(notifyState);
  const setCookies = () => {
    setCookie(null, 'closeNotifyCookie', 'true');
    setOpen('true');
  };
  return (
    !notifyState &&
    !onOpen && (
      <div className={styles.wrapper}>
        <div className={'container'}>
          <div className={styles.wrapperInside}>
            <div>
              <div className={styles.header}>Мы используем файлы cookie</div>
              <div className={styles.bodyText}>
                Некоторые функции могут не работать, если не установлены
                cookies.
                <br /> Чтобы узнать больше об использовании cookies на нашем
                сайте, перейдите на{' '}
                <a
                  className={styles.bodyTextLink}
                  href={'/personalData'}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  информационную страницу Cookies.
                </a>
              </div>
            </div>
            {!isMobile ? (
              <div>
                <Button view={'primary'} onClick={() => setCookies()}>
                  Закрыть
                </Button>
              </div>
            ) : (
              <div className={styles.closeButtonAside}>
                <div
                  className={styles.closeButton}
                  onClick={() => setCookies()}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};
