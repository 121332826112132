import React, { useState } from 'react';
import { DropDown } from '../DropDown';
import styles from './searchCity.module.sass';
import { SearchInput } from '../SearchInput';
import { CheckboxList } from '../DropDown/components/CheckboxList';
import { YaAndGaSearchLine } from '../../lib/YaAndGaHelper';

export const citiesToString = arr => {
  if (!arr.length) return '';
  const str = arr.join(', ');
  const limit = 30;

  if (str.length > limit) {
    return `${str.slice(0, limit)}...`;
  }

  return str;
};

export const SearchCity = ({
  value: val,
  id,
  placeholder,
  label,
  error,
  cities = [],
  actions,
  isBodyRight,
  disabled,
  view,
  loading,
  firstLoading,
  onClickField,
  onClearButtonClick,
  actionOnClick,
  metric,
  ...props
}) => {
  const [search, setSearch] = useState('');

  const filterCities = (arr = [], str) =>
    arr?.filter(i => i?.toLowerCase().includes(str.toLowerCase()));

  return (
    <DropDown
      firstLoading={firstLoading}
      value={citiesToString(val)}
      id={id}
      placeholder={placeholder}
      label={label}
      error={error}
      disabled={disabled}
      isBodyRight={isBodyRight}
      view={view}
      metric={metric}
      onClickField={onClickField}
      onClearButtonClick={onClearButtonClick}
      content={
        <div>
          {actions && <div className={styles.searchCityActions}>{actions}</div>}
          <SearchInput
            placeholder={
              id === 'direction' ? 'Поиск направления' : 'Поиск города'
            }
            onChange={e => setSearch(e.target.value)}
            onClick={() => {
              YaAndGaSearchLine(
                id === 'departure'
                  ? 'click_field_search_city_departure'
                  : 'click_field_search_city_arrival',
              );
              window.ym(
                35441960,
                'reachGoal',
                id === 'departure'
                  ? 'main_search_search_by_city_in_departure_city_field'
                  : 'main_search_search_by_destination_in_destination_field',
              );
            }}
          />
          <CheckboxList
            items={filterCities(cities, search)}
            name={id}
            view={view}
            actionOnClick={actionOnClick}
            disabled={loading}
            additionalText
          />
        </div>
      }
      {...props}
    />
  );
};

SearchCity.defaultProps = {
  error: undefined,
  actions: null,
  cities: [],
};
